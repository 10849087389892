.item .page-header .container{
    padding-top: 0;
}
.home-slider-next{
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 9;
    a{
        //border-radius: 45px;
        //background: white;
        color: white;
        font-size: 40px;
        line-height: 1;
        //line-height: 40px;
        //width: 40px;
        //height: 40px;
        text-align: center;
        display: inline-block;
        .fa{
            width: auto;
            line-height: 1;
        }
    }
}
.item .btn{
    margin-top: -20px;
    margin-bottom: 40px;
    color: black;
    font-weight: bold;
}
.slider-title.text-white{
    color: white;
    padding: 10px 0px;
    line-height: 1.3;
    display: inline-block;
}
.slider-title.text-black{
    color: black;
    padding: 10px 0px;
    line-height: 1.3;
    display: inline-block;
}
.text-left.slider-text,
.text-right.slider-text{
    .text{
        display: block;
    }
}
.slider-text{
    position: relative;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: translate3d(0,0,0);
    color: white;
    //font-style: italic;
    .text-sub{
        display: block;
        padding-bottom: 15px;
    }
    //font-weight: bold;
    .text{
        //text-transform: uppercase;
        line-height: 1.4;
        line-height: 1.2;
        //font-weight: bold;
        //line-height: 1.7em;
        display: inline-block;
        //margin: 0 17px;
        //font-family: $title-font;
        //padding: 8px 16px;
        //font-family: 'Abside';
        &.text-xs{
            font-size: 14px;
            h2{
                font-size: 21px;
            }
            @media (max-width: 1199px) {
                font-size: 13px;
            }
            @media (max-width: 991px) {
                font-size: 12px;
            }
            @media (max-width: 767px) {
                font-size: 11px;
            }
        }
        &.text-sm{
            font-size: 24px;
            h2{
                font-size: 29px;
            }
            @media (max-width: 1199px) {
                font-size: 22px;
            }
            @media (max-width: 991px) {
                font-size: 20px;
            }
            @media (max-width: 767px) {
                font-size: 18px;
            }
        }
        &.text-md{
            font-size: 32px;
            @media (max-width: 1199px) {
                font-size: 28px;
            }
            @media (max-width: 991px) {
                font-size: 24px;
            }
            @media (max-width: 767px) {
                font-size: 20px;
            }
            h2{
                font-size: 42px;
                @media (max-width: 1199px) {
                    font-size: 36px;
                    line-height: 1.2;
                }
                @media (max-width: 991px) {
                    font-size: 30px;
                    line-height: 1.2;
                }
                @media (max-width: 767px) {
                    font-size: 24px;
                    line-height: 1.2;
                }
            }
            @media (max-width: 1199px) {
                font-size: 27px;
            }
            @media (max-width: 991px) {
                font-size: 22px;
            }
            @media (max-width: 767px) {
                font-size: 16px;
            }
        }
        &.text-lg{
            //font-size: 48px;
            p{
                font-size: 15px;
                line-height: 1.4;
            }
            h2{
                font-size: 2.825em;
                line-height: 1.4;
                font-weight: bold;
                @media (max-width: 1199px) {
                    font-size: 2.625em;
                    line-height: 1.2;
                }
                @media (max-width: 991px) {
                    font-size: 2.425em;
                    line-height: 1.2;
                }
                @media (max-width: 767px) {
                    font-size: 2.225em;
                    line-height: 1.2;
                }
            }
            @media (max-width: 1199px) {
                //font-size: 40px;
            }
            @media (max-width: 991px) {
                //font-size: 32px;
            }
            @media (max-width: 767px) {
                //font-size: 24px;
            }
        }
        &.text-xl{
            p{
                font-size: 15px;
                line-height: 1.4;
            }
            h2{
                font-size: 2.825em;
                line-height: 1.4;
                font-weight: bold;
                @media (max-width: 1199px) {
                    font-size: 2.625em;
                    line-height: 1.2;
                }
                @media (max-width: 991px) {
                    font-size: 2.425em;
                    line-height: 1.2;
                }
                @media (max-width: 767px) {
                    font-size: 2.225em;
                    line-height: 1.2;
                }
            }
            @media (max-width: 1199px) {
                //font-size: 52px;
            }
            @media (max-width: 991px) {
                //font-size: 40px;
            }
            @media (max-width: 767px) {
                //font-size: 32px;
            }
        }
    }
}
.carousel-text-position-top,
.carousel-text-position-middle,
.carousel-text-position-bottom{
    position: absolute;
    left: 0;
    right: 0;
}
.carousel-text-position-top{
    top: 100px;
}
.carousel-text-position-middle{
    top: 52%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 10000000;
    @media (max-width: 767px) {}
}
.carousel-text-position-bottom{
    bottom: 10%;
    @media (max-width: 767px) {
        bottom: 50px;
    }
}
.carousel-indicators .active{
    background-color: $info-color;
}
.carousel-control{
    //background-color: $info-color;
    //border-radius: 50%;
    opacity: 1;
    background: none;
    text-shadow: none;
    &:hover{
        //background-color: $info-color;
        background: none;
    }
}
.slider-with-intro-arrow .carousel-text-position-bottom{
    bottom: 55px;
}
.page-header{
    //border-bottom: 5px solid $brand-dark;
}
.page-header.page-header-fs{
    min-height: 100vh;
    min-height: calc(100vh - 83px);
    height: auto;
    @media (max-width: 767px) {
        min-height: 60vh;
    }
}
.page-header.page-header-hs {
  min-height: 45vh;
  height: auto;
}
.page-header.page-header-25 {
  min-height: 30vh;
  height: auto;
}
.page-header.page-header-75 {
  min-height: 70vh;
  height: auto;
}