.card{
	background-color:rgba(255,255,255,0.8) !important;
}
.card.services{
	 h4{
	    color: #AD0013 !important;
	    font-weight: bold;
	}
	p{
		color: #000 !important;
	}
	
}
.reserveren{
	color: #AD0013 !important;
}
.btn-info a:hover{
	background-color: #E2011B !important;
}



.pb-logo{

	width: 326px;
	height:84px;
	display: inline-block;
	overflow: hidden;
	margin-top:10px;

	.black{
		color: #000;
	}
	img{
		height:75px;

		float:left;
	}
	span{
		position: absolute;
		font-size: 28px;
		margin-left: 10px;
		margin-top:42px;
	}
	
}
@media (max-width: 576px) {	

.pb-logo{

	width: 326px;
	height:84px;
	display: inline-block;
	overflow: hidden;
	margin-top:10px;
	margin-left: 5px;

	.black{
		color: #000;
	}
	img{
		height:75px;

		float:left;
	}
	span{
		position: absolute;
		font-size: 20px;
		margin-left: 10px;
		margin-top:51px;
	}
	
}
			
}
.wit h2{
	color: #fff !important;
}

.footer{
	background-color: #fff !important;
	border-top:1px solid #000;
}

.footer .links ul a:not(.btn) {
    color: #000;
    display: block;
    font-size: 0.9em;
    margin-bottom: 3px;
}
.footer .copyright {
    color: #000;
    font-size: 0.9em;
}

.full-bg{
	background-color: #f1f1f1;
	width:100%;
	padding: 40px 0;
	margin-top:60px;
	@media (max-width: 576px) {	
		padding: 10px 0;
		margin-top:0px;
		}	
}
.space{
	margin-top:140px;
	@media (max-width: 576px) {	
		
		margin-top:100px;
		
		}
	
}

.title.filialen.wit{
	color: #fff !important;
}

.homelocations a{
	display: block;
	background-color: #fff;
	color: #000 !important;
	-webkit-transition: all .4s ease;
-moz-transition: all .4s ease;
-o-transition: all .4s ease;
transition: all .4s ease;
	
}
.homelocations a:hover{
	background-color: #AD0013;
	color: #fff !important;
	text-decoration: none;
	-webkit-transition: all .4s ease;
-moz-transition: all .4s ease;
-o-transition: all .4s ease;
transition: all .4s ease;
	
}


/* card overwrites voor Pieterbas */
.card.services{
	margin-bottom:60px !important;
	margin-top:60px !important;
	background-color: #000;
	h4{
		color:#fff;
		font-weight: bold;
		@media (max-width: 576px){
			font-size: 20px;
			}
	}
	p {
		color: $pbred-color;
		font-weight: 600 !important;
		font-size: 16px;
	}
}
.card-profile .card-avatar.border-white {
    border: 8px solid #FFFFFF;
}

.card-profile .card-avatar {
    max-width: 200px;
    max-height: 200px;
    margin: -100px auto 0;
    border-radius: 50%;
    overflow: hidden;
}

/* end */

/* extra overwrites */
.btn {
    font-size: 16px;
    font-weight:bold;
    cursor: pointer;
    text-transform: none !important;
    font-family: 'Montserrat', sans-serif !important;
    }
    
.navbar .navbar-nav .nav-item .nav-link {
    font-size: 16px;
    text-transform: none;
}
.site .navbar-nav {
    margin-top: 41px !important;
}

blockquote {
    line-height: normal !important;
    font-size: 24px !important;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    text-transform: none !important;
    color: #000;
}

blockquote{
	padding-bottom: 0px !important;
}

@media (min-width: 992px) {
	.section-home h1.title{
    margin: 0px 0 15px;
    }
}

/* end */

/* language button mobile placement */
@media screen and (max-width: 991px){
.navbar-collapse ul{
    position: relative;
    z-index: 3;
    height: 4% !important;
}

.nav-lang {
    margin-top: 240px !important;
    margin-left: 10px;
}
}
/* end */



.intro-text{
	background-color: $pbred-color;
	padding:15px 15px 15px 15px;
	margin-top:30px !important;
	
	h2, p{
		color:white
	}
	h2{
		margin-top: 0px;
	}
	p{
		font-size:18px;
		@media (max-width: 767px){
			font-size: 14px;
			}
	}
}
.intro-text.blauw{
	background-color: #8ac8ed;
}


.radio-holder{
	background-color: #f1f1f1;
	padding:15px;
	margin-bottom:15px;
}
label.error{
	color: $pbred-color;
}

.nav-lang{
	margin-top:53px;
	margin-left:10px;
}



.carousel-indicators .active {
    background-color: $pbred-color !important;
}
.title.filialen{
	color: $pbred-color !important;
	margin-top:0px;
}

@media (max-width: 767px){
	

.topmargin{
		margin-top:30px !important;
	}
.title.filialen{
	color: $pbred-color !important;
	margin-top:40px;
}	
}

.btn-info {
    background-color: $pbred-color;
    border-color: $pbred-color;
    color: #FFFFFF;
    opacity: 1;
    filter: alpha(opacity=100);
}
.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
    background-color: $pbred-color;
    color: #FFFFFF;
    border-color: $pbred-color;
    filter: alpha(opacity=10);
}
.location-img-holder{
	width:30%;
	position: relative;
	float:left;
	margin-right:5px;
}
.location-img a{
	width:100%;
	height:100%;
	position: absolute;
	top:0px;
	left:0px;
}
.location-img {
    background-size: cover;
    background-position: center center;
    width: 100%;
   padding-top: 56.25%;
}
.rounded-circle{
	border:15px solid #000;
}

.page-header-wrapper{
	margin-top:90px;
}

.prijzen h3, .prijzen p{
	color: #fff;
}
.prijzen h3{
	font-weight: bold;
}
.prijzen p{
	font-size: 18px;
}
.service-holder{
	overflow: hidden;
	padding-top:80px;
}
.service h2{
	margin-bottom: 0px;	
	font-weight: bold;
	@media (max-width: 767px){
		font-size: 20px !important;
	}
}
.service h3{
	margin-top: 0px;
	@media (max-width: 767px){
		font-size: 18px !important;
	}
}

@media (max-width: 576px){
		.service img{
			float:left !important;
			width:100px;
		}
		.service.mt-5 {
			margin-top:2em !important;
		}
		.service .os-animation{
			margin-top:10px;
		}
		
	}

@media (max-width: 767px){
	.mob-left{
		float:left !important;
	}
	}

.cookie{
    position: fixed;
    z-index: 9999999999;
    left: 0;
    bottom: 0;
    right: 0;
    background: #c96f6f;
    color: white;
    padding: 10px 10px 10px 10px;
    display: none;
    overflow: hidden;
}
.cookie p{
    margin: 0;
    font-size: 13px;
    line-height: 1.4;
    float: left;
}
.cookie a.close_cookie{
    position: relative;
    float: right;
    color: white;
    padding: 5px 10px;
    background: rgba(0,0,0,.5);
}
blockquote{
    width: 100%;
    border-left: 2px solid $pbred-color;
    background: white;
    line-height: 1.6;
    font-size: 21px;
    font-family: $font-family-heading;
    font-style: italic;
    position: relative;
    text-transform: uppercase;
    color: $pbred-color;
    padding: 20px;
    padding-left: 70px;
}
blockquote::before{
    font-family: FontAwesome;
    content: "\f10d";
    color: $pbred-color;
    font-size: 21px;
    position: absolute;
    left: 20px;
    top: 20px;
}
blockquote::after{
  content: '';
}
.overflow-hidden{
    overflow: hidden;
}
.text-image.img-responsive{
    width: 100%;
}
a {
    color:#dd0000;
    font-weight: bold;
    
}
a:hover, a:focus {
    color: #dd0000;
    text-decoration: underline;
}
@media (max-width: 991px){
    .push-top-mob{
        margin-top: 30px;
    }
    .home-reserveren a{
	font-size: 13px;
}
}
.parallax-window.no-bg{
	background: none;
	padding-bottom: 0px;
}
.parallax-window{
    blockquote{
        width: 100%;
        border-left: 2px solid black;
        background: none;
        //background: rgba(0,0,0,.1);
        line-height: 1.6;
        font-size: 21px;
        font-family: $font-family-heading;
        font-style: italic;
        position: relative;
        text-transform: uppercase;
        color: black;
        padding: 20px;
        padding-left: 70px;
    }
    blockquote::before{
        font-family: FontAwesome;
        content: "\f10d";
        color: black;
        font-size: 21px;
        position: absolute;
        left: 20px;
        top: 20px;
    }
}
.home-content{
    padding-bottom: 30px;
}
.info-box{
    background:  #f1f1f1;
    padding: 5px 20px 20px;
    h2{
	    color:#000;
    }
}
$tile-title: $pbred-color;
.card.card-plain{
    h2.subtitle{
        margin-bottom: 15px;
    }
    &.card-border{
        border: 1px solid #eee;
        padding: 15px;
        h1,h2,.title,subtitle{
            margin-top: 10px;
        }
    }
    .card-body{
        padding: 0;
        overflow: hidden;
    }
    img.school-logo{
        width: 140px;
        max-width: 100%;
        display: block;
        border: 1px solid #eee;
        border-radius: 0;
    }
    p.card-text{
        padding: 15px 0;
        line-height: 1.6;
        a{
            color: $pbred-color;
            color: black;
            font-size: $font-paragraph;
            font-size: 16px;
            font-weight: $font-weight-light;
            font-weight: 200;
            //line-height: $line-height-general;
            line-height: 1.5em;
            i{
                color: $pbred-color;
            }
        }
        a:hover{
            text-decoration: underline;
        }
    }
}
.scholen-home{
    //padding: 0px 15px 0px 15px;
    //background: #eee;
    //margin-top: -50px;
    //margin-bottom: -20px;
    h2.title{
        //font-size: $font-size-h3;
        //text-transform: uppercase;
        //color: $tile-title;
    }
    .img-link{
        .img-link-child{}
    }
    .school-tile{
        margin-bottom: 15px;
        .tile-title{
            font-size: 16px;
            text-transform: uppercase;
            margin: 8px 0 12px;
            padding: 0;
            color: $tile-title;
        }
        p{
            font-size: 16px;
            font-weight: 200;
            a{
                font-size: 16px;
                font-weight: 200;
                color: $white-color;
            }
        }
    }
}
.accordion{
    .card.card-plain{
        margin-bottom: 0;
        .card-header{
            .btn-link{
                padding: 0px 0px;
            }
            .btn:after{
                font-family: FontAwesome;
                content: "\f106";
                color: $pbred-color;
                color: #66615B;
                font-size: 1.35em;
                position: absolute;
                right: 20px;
                top: 12px;
            }
            .btn.collapsed:after{
                content: "\f107";
            }
        }
        .card-body{
            padding: .75rem 1.25rem .2rem;
            border: 1px solid #eee;
            .card-contacts,
            .card-address{
                p{
                    font-size: 15px;
                }
            }
        }
    }
}
[data-toggle="collapse"] .fa:after {
  //content: "\f139";
}

[data-toggle="collapse"].collapsed .fa:after {
  //content: "\f13a";
}
.site{
    position: relative;
    z-index: 0;
    
    h1, h2, h3, h4, h5, h6, button, .navbar a{
        font-family: $font-family-heading;
    }
    .hidden{
        display: none;
    }
    .bg-white{
        background: white;
    }
    .bg-gray{
        background: #eee;
    }
    .meesterbaan{
        padding-left: 10px;
        padding-right: 10px;
    }
    .navbar-nav{
	    margin-top:30px;
    }
    .members-list{
        margin: 20px 0;
        .member{
            //font-size: 16px;
            //margin-bottom: 10px;
            .member-name{
                display: inline-block;
                font-size: 19px;
                line-height: 1.5;
                color: $pbred-color;
                position: relative;
                .member-socials{
                    position: relative;
                    overflow: hidden;
                    top: 7px;
                    margin-left: 15px;
                    padding-right: 1px;
                    float: right;
                    line-height: 11px;
                    .member-social{
                        float: left;
                        margin-right: -1px;
                        position: relative;
                        font-size: 11px;
                        line-height: 11px;
                        padding: 2px 2px;
                        border-radius: 0;
                        display: inline-block;
                        color: $pbred-color;
                        border: 1px solid $pbred-color;
                        color: #999;
                        border: 1px solid #ddd;
                        &:hover{
                            background: $pbred-color;
                            border: 1px solid $pbred-color;
                            color: white;
                        }
                    }
                }
                //font-weight: bold;
            }
            .member-sub{
                display: block;
                font-size: .8em;
                font-style: italic;
                color: #777;
                line-height: 1.5;
            }
        }
    }
    .social-line-gray{
        border-top: 1px solid #eee;
    }
    .pb-page{
        padding-bottom: 40px;
    }
    &.transparent{
        background: transparent;
    }
    .container{
        //max-width: 970px;
    }
    .breadcrumbs-xl{}
    .breadcrumb{
        background: none;
    }
    .wrapper{
        background: transparent;
    }
    .card-description{
        padding-top: 10px;
        a{
            color: $pbred-color;
            text-decoration: underline;
        }
        ul,ol{
            padding: 10px 0 20px;
            li{
                display: block;
                list-style: none;
                padding-top: 2px;
                padding-bottom: 2px;
            }
            li {
                padding-left: 1em;
            }
            li:before {
                content: "\f0da"; /* FontAwesome Unicode */
                font-family: FontAwesome;
                display: inline-block;
                margin-left: -1em; /* same as padding-left set on li */
                width: 1em; /* same as padding-left set on li */
            }
        }
    }
    .navbar{
        box-shadow: none;
    }
    .navbar.navbar-expand-lg{
        padding-top: 5px;
        .navbar-brand,
        .nav-item .nav-link{
            opacity: 1;
        }
    }
    .navbar-top{
        background: $pbred-color;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 3px;
    }
    .navbar-brand{
        padding: 0;
        position: relative;
        height: 84px;
        .logo{
            top: 11px;
            position: absolute;
            width:75px;
            
            
            @media (max-width: 575px) {
                left: 15px;
                              
            }
        }
    }
    .navbar-toggler{
        @media (max-width: 575px) {
            position: relative;
            right: 15px;
        }
    }
    .navbar[class*="bg-"] .navbar-toggler .navbar-toggler-bar,
    .navbar.navbar-transparent .navbar-toggler .navbar-toggler-bar{
        background: $pbred-color;
    }
    @media (max-width: 991px){
    .collapse.navbar-collapse{
        background-color: $pbred-color;
        border-top: none;
        border-left: 1px solid darken($pbred-color,10%);
        .navbar-nav{
            margin: 0;
            margin-left: auto !important;
            .nav-link{
                padding: 15px 8px;
                color: white !important;
                background: rgba(255,255,255,.05);
            }
            .nav-link:hover{
                color: white !important;
                background: rgba(255,255,255,.2);
            }
        }
    }
    .collapse.navbar-collapse::after{
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        background-color: $pbred-color;
        border-left: 0 solid red;
        display: block;
        content: "";
        z-index: 1;
    }
    }
    .logo_kleur{
        display: block;
    }
    .logo-wit{
        display: none;
    }
    .navbar .navbar-nav .nav-item .nav-link{
        //padding: 30px 15px 0px;
    }
    .navbar.bg-default{
        background: #fff;
       // -webkit-box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.1);
		//-moz-box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.1);
		//box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.1);
		
        //border-bottom: 1px solid #000;
        .navbar-nav{
            .nav-link:not(.btn),
            .dropdown-item{
                color: $pbred-color;
            }
            .dropdown-item.active{
                color: white;
                background: $pbred-color;
            }
            .nav-item .nav-link{
                color: #000;
            }
            .nav-item .nav-link:hover,
            .nav-item.active .nav-link{
                color: $pbred-color;
                border-bottom: 0px solid $pbred-color;
            }
        }
    }
    .navbar.bg-default.navbar-transparent{
        .navbar-nav{
            .nav-link:not(.btn){
                color: white;
            }
            .dropdown-item.active{
                color: white;
                background: $pbred-color;
            }
        }
    }
    .navbar .navbar-nav .nav-item .nav-link{
        margin: 10px 3px 0px;
        padding: 5px 15px 5px;
    }
    .navbar-transparent{
        .logo_kleur{
            display: none;
        }
        .logo-wit{
            display: block;
        }
    }
    
    .header-wrapper{
        height: 550px;
    }
    .section{
        padding-top: 20px;
        background: none;
    }
    .section-home{
        padding-top: 80px;
        padding-bottom: 70px;
        @media (max-width: 991px){
	        padding-top: 0px
	        }
    }
    h3.title-uppercase{
       margin-top: 50px;
    }
    p{
        margin-top: 0px;
    }
    .creators{
        margin-top: 100px;
    }
    .more-info{
        margin-top: 50px;
        margin-bottom: 30px;
    }
    .section-with-breadcrumbs{
        padding-top: 5px;
    }
    .breadcrumb{
        margin-bottom: 0px;
    }
    .zoom-parent{
        display: block;
        position: relative;
        padding: 59.25% 0 0 0;
        //margin-top: 20px;
        //margin-bottom: 20px;
        overflow: hidden;
        //border-radius: 12px;
        //-webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        .zoom-child{
            //border-radius: 12px;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: #ccc;
            background-repeat: no-repeat;
            background-position: center center fixed;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            transition: all .9s;
        }
        &:hover .zoom-child,
        &:focus .zoom-child {
            border-radius: 12px;
            transform: scale(1.1);
        }
    }
}
@mixin theme-mixing($color) {
    .list-group-item.active {
        background-color: lighten($color,10%);
        border-color: lighten($color,10%);
    }
    .dropdown-item{
        &.active{
            background-color: lighten($color,7%);
            color: white;
        }
    }
    .dropdown-item:hover{
        background-color: lighten($color,7%) !important;
        color: white !important;
    }
    .pagination > li > a,
    .pagination > li > span,
    .pagination > li:first-child > a,
    .pagination > li:first-child > span,
    .pagination > li:last-child > a,
    .pagination > li:last-child > span {
      border: 2px solid $color;
      color: $color;
    }
    .pagination > li > a:hover,
    .pagination > li > a:focus,
    .pagination > li > a:active,
    .pagination > li.active > a,
    .pagination > li.active > span,
    .pagination > li.active > a:hover,
    .pagination > li.active > span:hover,
    .pagination > li.active > a:focus,
    .pagination > li.active > span:focus {
      background-color: $color;
      border-color: $color;
      color: #FFFFFF;
    }
    .section.section-page-color{
        background-color: lighten($brown-color, 7%);
        color: white;
        h3{
            margin-top: 20px;
        }
    }
}
body{
    &.color-default{
        @include theme-mixing($default-color);
    }
    &.color-primary{
        @include theme-mixing($primary-color);
    }
    &.color-info{
        @include theme-mixing($pbred-color);
    }
    &.color-success{
        @include theme-mixing($success-color);
    }
    &.color-warning{
        @include theme-mixing($warning-color);
    }
    &.color-danger{
        @include theme-mixing($danger-color);
    }
}
body{
    color: black;
    font-family: $font-family-sans-serif; 
    
    b, strong{
        //font-weight: 300;
    }
    .section-grey{
        background: #eee;
    }
    .section-white{
        background: white;
    }
    .home-intro{
        padding-top: 0px;
        padding-bottom: 0px;
        p{
            font-size: 19px;
        }
    }
    .list-group-item.active {
        z-index: 2;
        color: #fff;
        //background-color: #f5593d;
        //border-color: #f5593d;
    }
   h2, h1.title,
    h2.title,
    h3.title{
        font-size: 2.5em;
        line-height: 1.4;
        margin: 20px 0 15px;
        line-height: normal;
        color: black;
        font-weight: bold;
        //margin-top: 50px;
        @media (max-width: 575px){
            font-size: 1.825em;
        }
        @media (max-width: 767px){
            font-size: 2.325em;
        }
        @media (max-width: 991px){
            font-size: 2.325em;
        }       
    }
    h1.subtitle,
    h2.subtitle,
    h3.subtitle{
        font-size: 1.425em;
        font-size: 1.725em;
        color: $pbred-color;
        color: black;
        line-height: 1.4;
        margin: 20px 0 20px;
        font-weight: 600;
    }
    .title-row{
        h1.title,
        h2.title,
        h3.title{
            margin-bottom: 40px;
        }
    }
    .title-news{
        margin-bottom: 30px !important;
    }
    .card{
        .card-description{
            margin-top: 0;
            .btn{
                margin-top: 20px;
            }
        }
    }
    .text-main{
        p{
            font-size: 16px;
            font-weight: normal;
            margin-bottom: 15px;
        }
        ul,ol{
            padding: 10px 0 20px;
            li{
                display: block;
                list-style: none;
                padding-top: 2px;
                padding-bottom: 2px;
                font-size: 16px;
            }
            li {
                padding-left: 1em;
            }
            li:before {
                content: "\f0da"; /* FontAwesome Unicode */
                font-family: FontAwesome;
                display: inline-block;
                margin-left: -1em; /* same as padding-left set on li */
                width: 1em; /* same as padding-left set on li */
            }
        }
        img.text-image{
            border: 3px solid white;
            box-shadow: 0 0 5px rgba(0,0,0,.3);
            margin: 0px 0px 15px;
            width: 100%;
            //max-width: 380px;
            display: block;
            &.left{
                float: left;
                margin-right: 15px;
            }
            &.right{
                float: right;
                margin-left: 15px;
            }
        }
        .text-block{
            display: block;
            clear: both;
            h2{
                font-size: 24px;
                font-weight: 600;
                margin: 30px 0 15px;
                display: block;
            }
            @media(max-width: 576px){
                img:not(.img-responsive){
                    display: inline-block;
                    width: 60% !important;
                    margin-left: 20% !important;
                    margin-right: 20% !important;
                    height: auto !important;
                }
            }
        }
    }
    .intro{
        margin-bottom: 15px;
        
        ul,ol{
            padding: 10px 0 10px;
            margin-bottom: 0;
            li{
                display: block;
                list-style: none;
                padding-top: 2px;
                padding-bottom: 2px;
                font-size: 19px;
            }
            li {
                padding-left: 1em;
            }
            li:before {
                content: "\f0da"; /* FontAwesome Unicode */
                font-family: FontAwesome;
                display: inline-block;
                margin-left: -1em; /* same as padding-left set on li */
                width: 1em; /* same as padding-left set on li */
            }
        }
    }
    .text-intro{
        p{
            @media (min-width: 576px){
                margin-top: 0;
            }
            font-weight: bold;
        }
    }
    .home-contact{
        .image-landscape{
            display: block;
            position: relative;
            padding: 75% 0 0 0;
            //margin-top: 20px;
            //margin-bottom: 20px;
            overflow: hidden;
            margin-top: 10px;
        }
        h2.title{
            margin-top: 0;
            padding-top: 0;
        }
    }
    $footer-box-shadow: 0px -1px 1px 1px rgba(0,0,0,0.12);
    $footer-color: $pbred-color;
    $footer-bg-color: white;
    .footer-default{
        //-webkit-box-shadow: $footer-box-shadow;
        //-moz-box-shadow: $footer-box-shadow;
        //box-shadow: $footer-box-shadow;
        background: lighten($pbred-color, 50%);
        background: black;
        border-top: 1px solid #eee;
        color: $footer-color;
        margin-top: 0;
        h4{
            color: $footer-color;
           
            margin: 0 0 15px;
            padding: 0;
            font-size: 16px;
            line-height: 1;
            text-transform: uppercase;
        }
        &.footer-big{
            padding-bottom: 0px;
        }
        hr{
            color: $pbred-color;
            border-color: lighten($pbred-color,20%);
        }
        .links ul.stacked-links{
            margin: 0 0 25px;
        }
        .footernav{
            padding: 0;
            margin: 0;
            .nav-link{
                padding: 0;
            }
        }
        .microdata{
            font-size: 16px;
            line-height: 26px;
            .microText{
                text-transform: uppercase;
                //font-family: $font-family-heading;
                font-family: $font-family-sans-serif;
                font-size: 0.9em;
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
            }
        }
        .footer-brand{
            img{
                display: block;
                width: 100%;
                max-width: 120px;
            }
            margin-bottom: 15px;
        }
        .uppercase-links.stacked-links a,
        .nav-link{
            color: $footer-color;
        }
        .copyright{
            background: $pbred-color;
            color: white;
            margin: 0;
            font-weight: normal;
            font-size: 11px;
            a{
                color: white;
                opacity: 1;
                font-weight: bold;
            }
        }
    }
    $img-link-padding: 25px;
    .img-link{
        position: relative;
        display: block;
        border: 1px solid $pbred-color;
        background-color: $pbred-color;

        border: 1px solid #eee;
        background-color: white;
        padding: 66.25% 0 0 0;
        .img-link-child{
            position: absolute;
            left: $img-link-padding;
            right: $img-link-padding;
            top: $img-link-padding;
            bottom: $img-link-padding;
            overflow: hidden;
            //width: 100%;
            //height: 100%;
            background-repeat: no-repeat;
            background-position: center center fixed;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            transition: all .4s;
        }
        &:hover .img-link-child,
        &:focus .img-link-child {
            border-radius: 12px;
            transform: scale(1.1);
        }
    }
    .page-header .filter::after{
        opacity: .5;
    }
    #main-content{
        position: relative;
    }
    &.home{
        #main-content{
            //padding-left: 300px;
        }
        .single.active.nav-item{
            i.fa{
                color: white;
            }
        }
    }
    &.splash_active{
        overflow: hidden;
        height: 100vh;
    }
    .splash-buttons{
        left: 350px;
        right: 0;
        @media (min-width: 576px) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        @media (max-width: 991px) {
            left: 0;
        }
    }
    .splash-link{
        margin-bottom: 15px;
    }
    #progressBar {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 22px;
        background-color: #eee;
        z-index: 1040;
        color: white;
    }
    #progressBar div {
      height: 100%;
      text-align: right;
      padding: 0 10px;
      line-height: 22px; /* same as #progressBar height if we want text middle aligned */
      width: 0;
      background-color: $pbred-color;
      color: white;
      box-sizing: border-box;
    }
    $splash-height: 100vh;
    .splash{
        position: fixed;
        //top: -220%;
        //bottom: 120%;
        top: 5px;
        bottom: 5px;
        left: 5px;
        right: 5px;
        z-index: 1200;
        z-index: 1040;
        background: rgba(255,255,255,1);
        padding-left: 350px;
        border-radius: 5px;
        overflow-y: scroll;
        display: none;
        -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.75);
        -webkit-transition: all .4s ease-out;
        transition: all .4s ease-out;
        @media (max-width: 991px) {
            padding-left: 0px;
        }
        @media (max-width: 768px) {
            padding-top: 60px;
            padding-bottom: 60px;
            .card.card-plain.card-border{
                background: rgba(255,255,255,.95);
            }
        }
        &.active{
            //top: 5px;
            //bottom: 5px;
        }
        .btn-close-splash{
            position: absolute;
            right: 36px;
            top: 15px;
            color: $pbred-color;
            font-size: 32px;
        }
            .logo-vertical{
                position: absolute;
                @media (max-width: 768px) {
                    position: fixed;
                    z-index: 0;
                    opacity: .4;
                    top: 60px;
                }
                left: 0;
                right: 0;
                //top: 0;
                bottom: 0;
                padding: 0 30px;
                img{
                    display: inline-block;
                    //position: absolute;
                    //bottom: 0;
                    //left: 30px;
                    //right: 30px;
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
    }
    .homeFeed{
        position: absolute;
        top: 5px;
        left: 5px;
        bottom:5px;
        width: 350px;
        z-index: 1020;
        padding: 15px;
        //margin-left: -15px;
        //margin-right: -15px;
        //padding-top: 1px;
        //padding-right: 1px;
        //padding-bottom: 1px;
        //padding-left: 1px;
        //height: $splash-height;
        //overflow-y: scroll;
        //overflow-x: visible;
        overflow: hidden;
        //background: $pbred-color;
        background: white;
        @media (max-width: 991px) {
            display: none;
        }
    }
    .feedItem{
        width: 48%;
        display: block;
        float: left;
        border: 5px solid white;
            -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.25);
            -moz-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.25);
            box-shadow: -1px -1px 4px 2px rgba(0,0,0,0.25);
        &.x2{
            //width: 50%;
        }
        img{
            display: inline-block;
            width: 100%;

        }
    }
    .img-responsive{
        max-width: 100%;
    }
}
.ekko-lightbox{
    .modal-dialog{
        background: none;
        .modal-content{
            background: none;
            border: 5px solid white;
            -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.35);
            -moz-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.35);
            box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.35);
            border-radius: 0;
            .modal-header.hide{
                padding: 0;
                margin: 0;
                height: 0 !important;
                border: 0;
                background: none;
                position: relative;
                .close{
                    position: absolute;
                    bottom: -55px;
                    right: 24px;
                    z-index: 1050;
                    font-size: 48px;
                    color: white;
                }
            }
            .modal-body{
                padding: 0;
                margin: 0;
            }
            .modal-footer.hide{
                padding: 0;
                margin: 0;
                height: 0;
            }
        }
    }
}

footer .nav-link {
    
    padding: 0rem 0rem !important;
}
.links{
	float:right;
}
/*

    SWERK NOTIFICATIONS

*/
.swerk-notifcatie{
    position: fixed;
    z-index: 999999999999;
    top: -100px;
    left: 0;
    right: 0;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    margin-bottom: 0;
}
.swerk-notifcatie .alert{
    min-height: 81px;
    /*max-height: 100px;*/
    border: 0;
    border-radius: 0;
    color: white;
    overflow: hidden;
    box-shadow: 0;
    box-shadow: inset 0 0 0 rgba(255,255,255,0);
}
.swerk-notifcatie .alert p{
    float: left;
    font-size: 14px;
    line-height: 52px;
    margin-top: 0;
}
.swerk-not-title{
    display: inline-block;
    margin-right: 6px;
    line-height: 52px;
    height: 22px;
    font-weight: bold;
    position: relative;
    padding-left: 25px;
}
.swerk-notifcatie .alert i{
    font-size: 20px;
    line-height: 52px;
    position: absolute;
    left: 0;
    top: -1px;
}
.swerk-notifcatie .alert-success{
    background: #27c24c;
}
.swerk-notifcatie .alert-info{
    background: #23b7e5;
}
.swerk-notifcatie .alert-danger{
    background: #f05050;
}
.swerk-notifcatie .alert-warning{
    background: #fad733;
}
.swerk-notifcatie .alert .close{
    color: white;
    opacity: 1;
    position: absolute;
    right: 15px;
    top: 15px;
}
.swerk-notifcatie .alert .close i{
    font-size: 18px;
}
