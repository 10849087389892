.after-parallax{
    position: relative;
    z-index: 1010;
}
.parallax-window{
    min-height: 50px;
    background: rgba(0,0,0,.6);
    position: relative;
    z-index: 1009;
    overflow: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 0;
}
.parallax-window.hairservices{
    min-height: 50px;
    background: rgba(255,255,255,.6);
    position: relative;
    z-index: 1009;
    overflow: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 0;
}
.parallax-intro{
    background:transparent;
    //padding: 30px 30px;
    color: white;
    color: black;
    border: 0;
    font-size: 16px;
    font-weight: 200;
    h2.title{
        color: white;
        
        margin: 0 0 10px;
        padding: 0;
    }
}